import { useCallback, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  useUpdate,
  useNotify,
  SimpleForm,
  Edit,
  useResourceContext,
  required,
  maxLength,
  useRefresh,
  minLength,
  AutocompleteInput,
  TextInput,
  DateInput,
  useTranslate,
  ReferenceInput,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import Box from "@mui/material/Box";
import TabForm from "../common/TabForm";
import currentDate from "../../../utils/currentDate";
import {
  customerFilterToQuery,
  customerInputText,
  customerMatchSuggestion,
  CustomerOptionRenderer,
} from "../../badDebtInstallments/common/CustomerSelectOption";
import TotalAmount from "../common/TotalAmount";
import CodeNumberInput from "../../common/CodeNumberInput";

const validateNumber = [required(), minLength(14), maxLength(14)];
const validateDate = [required(), minLength(10), maxLength(10)];
const validateCustomer = [required(), minLength(36), maxLength(36)];
const validateNote = [required()];

export default function BadDebtCorrectionEditDialog({
  open,
  setOpen,
  recordId,
}) {
  const [amountSource, setAmountSource] = useState("[]");
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext(); // cuman nama resource
  const t = useTranslate();

  const save = useCallback(
    async (values) => {
      values.bad_debt_id =
        values.bad_debt_correction_items[0].bad_debt_item.bad_debt_id;
      try {
        await update(
          resource,
          { id: values.id, data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.updated", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          if (error.body.errors === "403") {
            // munculkan notifikasi erro ketika sudah approved
            notify("mine.notification.403_error", { type: "error" });
          } else {
            // The shape of the returned validation errors must match the shape of the form
            return error.body.errors;
          }
        }
      }
    },
    [update]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted={false}
      fullWidth={true}
      maxWidth="md"
    >
      <Edit
        title={" "}
        id={recordId}
        actions={false}
        sx={{ "& .RaEdit-main": { mt: 0 } }}
      >
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns callback={handleClose} />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>
              {t("mine.dialog.edit_bad_debt_correction")}
            </DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent sx={{ alignSelf: "normal" }}>
            <TextInput disabled source="id" sx={{ display: "none" }} />

            <CodeNumberInput
              open={open}
              dependOn="date"
              variant="outlined"
              source="code"
              label="mine.label.code"
              fullWidth
              validate={validateNumber}
              disabled
            />
            <DateInput
              variant="outlined"
              source="date"
              label="mine.label.date"
              fullWidth
              isRequired
              validate={validateDate}
              defaultValue={currentDate()}
            />
            <ReferenceInput
              source="bad_debt_correction_items[0].bad_debt_item.bad_debt_id"
              reference="bad-debts"
              perPage={10}
              sort={{ field: "bad_debts.created_at", order: "ASC" }}
              filter={{ hasInstallments: true, "bad_debts.type": "2" }}
            >
              <AutocompleteInput
                label="mine.label.customer"
                variant="outlined"
                isRequired
                fullWidth
                validate={validateCustomer}
                optionValue="id"
                optionText={<CustomerOptionRenderer />}
                inputText={customerInputText}
                matchSuggestion={customerMatchSuggestion}
                filterToQuery={customerFilterToQuery}
              />
            </ReferenceInput>
            <TextInput
              variant="outlined"
              source="note"
              label="mine.label.note"
              fullWidth
              multiline
              rows={3}
              validate={validateNote}
            />
            <TabForm schema="edit" setAmountSource={setAmountSource} />
            <TotalAmount amountSources={amountSource} />
          </DialogContent>
        </SimpleForm>
      </Edit>
    </Dialog>
  );
}
