import { useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  DateField,
  BooleanField,
  NumberField,
  BulkDeleteWithConfirmButton,
  useResourceContext,
  useTheme,
  usePermissions,
} from "react-admin";
import CustomerDebtCorrectionAddDialog from "../../components/customerDebtCorrections/create/CustomerDebtCorrectionAddDialog";
import CustomerDebtCorrectionEditDialog from "../../components/customerDebtCorrections/edit/CustomerDebtCorrectionEditDialog";
import customerDebtCorrectionFilterList from "../../components/customerDebtCorrections/filter/customerDebtCorrectionFilterList";
import TableListActions from "../../components/tableListActions/TableListActions";
import CustomerDebtCorrectionExpandList from "../../components/customerDebtCorrections/expand/CustomerDebtCorrectionExpandList";
import EditBtn from "../../components/editBtn/EditBtn";
import ConfirmBtn from "../../components/confirmBtn/ConfirmBtn";
import DoneIcon from "@mui/icons-material/Done";

const CustomerDebtCorrectionList = () => {
  const resource = useResourceContext();
  const { permissions } = usePermissions();
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const [theme] = useTheme();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        title="mine.list_title.customer_debt_correction"
        empty={false}
        actions={
          <TableListActions
            createHandler={handleClickOpenAdd}
            exporter={false}
          />
        }
        filters={customerDebtCorrectionFilterList(permissions)}
        queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
          expand={<CustomerDebtCorrectionExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField source="company.alias" label="mine.tbl_header.company" />
          ) : null}
          <TextField source="code" label="mine.tbl_header.code" />
          <DateField source="date" label="mine.tbl_header.date" />
          <TextField source="customer.name" label="mine.tbl_header.customer" />
          <NumberField source="total" />
          <TextField source="note" label="mine.tbl_header.note" />
          <BooleanField
            source="approved"
            label="mine.tbl_header.approved"
            textAlign="center"
          />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              render={(record) => {
                return (
                  !record.approved && (
                    <>
                      <EditBtn
                        editHandler={handleClickOpenEdit(record.id)}
                        sx={{ mr: 1 }}
                      />
                      <ConfirmBtn
                        title="mine.message.approve"
                        content="mine.message.approve_content"
                        label="mine.btn.approve"
                        resource={resource + "/approve"}
                        startIcon={<DoneIcon />}
                      />
                    </>
                  )
                );
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    [theme]
  );
  return (
    <>
      {list}
      <CustomerDebtCorrectionAddDialog open={openAdd} setOpen={setOpenAdd} />
      <CustomerDebtCorrectionEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
    </>
  );
};

export default CustomerDebtCorrectionList;
