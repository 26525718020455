import { useEffect, useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  DateField,
  FunctionField,
  NumberField,
  BooleanField,
  BulkDeleteWithConfirmButton,
  useResourceContext,
  useTheme,
  useTranslate,
  usePermissions,
  RecordContextProvider,
} from "react-admin";
import SaleAddDialog from "../../components/sales/create/SaleAddDialog";
import SaleEditDialog from "../../components/sales/edit/SaleEditDialog";
import saleFilterList from "../../components/sales/filter/saleFilterList";
import TableListActions from "../../components/tableListActions/TableListActions";
import SaleExpandList from "../../components/sales/expand/SaleExpandList";
import ConfirmBtn from "../../components/confirmBtn/ConfirmBtn";
import EditBtn from "../../components/editBtn/EditBtn";
import DoneIcon from "@mui/icons-material/Done";
import PrintBtn from "../../components/printBtn/PrintBtn";
import PrintHandler from "../../components/printHandler/PrintHandler";
import SJ from "../../components/sales/print/SJ";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import PurchaseAsideList from "../../components/purchases/aside/PurchaseAsideList";
import DownloadAction from "../../components/tableListActions/DownloadAction";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const Aside = () => {
  const t = useTranslate();
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <h5>
        SJ gratis ditandai dengan huruf "P" di ujung kode SJ nya contoh:
        SJ-23/11-0006P, dan SJ tipe ini tidak bisa dirubah, hapus maupun
        diapprove, jika ingin merubah/menghapus/approve SJ tipe ini harus
        dilakukan melalui SJ utamanya yakni yang tidak ada huruf "P" diujungnya
        (SJ-23/11-0006).
        <br />
        <br />
        Setelah SJ utama di approve SJ gratis bisa dicetak sendiri terpisah dari
        SJ utamanya. Jumlah box yang digratiskan dihitung berdasarkan persentase
        yang disetting di menu {t("mine.label.promotions")} yang bisa di akses
        oleh administrator
        <br />
        <br />
        Meng-approve SJ urutannya harus sama dengan urutan Date/Tanggalnya, jika
        SJ tanggal 1 belum diapprove, SJ tanggal 2, 3, dst... tidak dapat
        diapprove, ini penting karena updated_at akan digunakan untuk keperluan
        saat retur sale
      </h5>
    </div>
  );
};

const SaleList = (props) => {
  const resource = useResourceContext();
  const { permissions } = usePermissions();
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const [showAside, setShowAside] = useState(false);
  const [resetPrintContent, setResetPrintContent] = useState();
  const [printRecord, setPrintRecord] = useState({}); // initial value wajib object kosong untuk cek key id di dependency useeffect DeliveryNote.js
  const [theme] = useTheme();

  useEffect(() => {
    setShowAside(resource === "sale-cards");
  }, [resource]);

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => (e) => {
    e.stopPropagation();
    setRecordId(recId);
    setOpenEdit(true);
  };
  const rowClickHandler = (id, basePath, record) => {
    setShowAside((prev) => !prev);
  };

  const forReport = resource === "sale-cards";

  const list = useMemo(() => {
    let actions = (
      <TableListActions
        createHandler={handleClickOpenAdd}
        filter={false}
        exporter={false}
      />
    );

    let filterDefaultValues = {
      start_date: firstDate,
      end_date: lastDate,
    };

    let bulkActionButtons = <BulkDeleteWithConfirmButton />;

    let boolAct = [
      <BooleanField
        key={1}
        source="approved"
        label="mine.tbl_header.approved"
        textAlign="center"
      />,
      <WrapperField key={2} label="mine.tbl_header.actions" textAlign="center">
        <WithRecord
          render={(record) => {
            return !record.approved ? (
              !record.promotion_id ? (
                <>
                  <EditBtn
                    editHandler={handleClickOpenEdit(record.id)}
                    // label=""
                    variant="outlined"
                    sx={{
                      width: "fit-content",
                    }}
                  />
                  <ConfirmBtn
                    title={"mine.message.approve"}
                    content="mine.message.approve_content"
                    label="mine.btn.approve"
                    resource={resource + "/approve"}
                    startIcon={<DoneIcon />}
                    variant="outlined"
                    sx={{
                      mx: 1,
                    }}
                  />
                </>
              ) : null
            ) : (
              <PrintBtn
                recordId={record.id}
                setPrintRecord={setPrintRecord}
                setResetPrintContent={setResetPrintContent}
              />
            );
          }}
        />
      </WrapperField>,
    ];

    if (forReport) {
      actions = <DownloadAction />;
      filterDefaultValues.approved = 1;
      bulkActionButtons = false;
      boolAct = null;
    }

    return (
      <List
        title={props.title ? props.title : undefined}
        empty={false}
        aside={showAside || forReport ? <PurchaseAsideList /> : null}
        actions={actions}
        filters={saleFilterList(permissions)}
        filterDefaultValues={filterDefaultValues}
        queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
        sx={{
          flexDirection: "column",
        }}
      >
        <DatagridConfigurable
          bulkActionButtons={bulkActionButtons}
          rowClick={rowClickHandler}
          rowSx={(record, index) => {
            // redupkan text SJ gratis
            if (record.code.endsWith("P")) {
              return {
                "& .RaDatagrid-rowCell": {
                  color: theme !== "dark" ? "grey.500" : "rgba(255,255,255,.4)",
                },
              };
            }
          }}
          expand={<SaleExpandList forReport={forReport} />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField label="mine.label.company" source="company.alias" />
          ) : null}
          <TextField source="code" label="mine.label.code" />
          <FunctionField
            label="mine.label.order_no"
            render={(record) =>
              record.sale_items[0].sale_order_item.sale_order.code
            }
          />
          <DateField source="date" label="mine.label.date" />
          <TextField
            source="sale_items[0].sale_order_item.sale_order.customer.name"
            label="mine.label.customer"
            emptyText="-"
          />
          <TextField
            source="sale_items[0].sale_order_item.sale_order.salesman.name"
            label="Salesman"
            emptyText="-"
          />
          <FunctionField
            label="Transport"
            render={(record) => `${record.transport.code}`}
          />
          <TextField
            source="promotion.name"
            label="mine.label.promotion"
            emptyText="-"
          />
          <FunctionField
            textAlign="right"
            label="Box"
            render={(record) => {
              let rec = { total_sent: 0 };
              for (let i = 0; i < record.sale_items.length; i++) {
                rec.total_sent += +record.sale_items[i].sent_quantity;
              }
              return (
                <RecordContextProvider value={rec}>
                  <NumberField source="total_sent" />
                </RecordContextProvider>
              );
            }}
          />
          <NumberField source="total" label="mine.label.value" />
          <TextField source="note" label="mine.label.note" emptyText="-" />
          {boolAct}
        </DatagridConfigurable>
      </List>
    );
  }, [showAside, forReport, theme]);
  return (
    <>
      {list}
      {!forReport && (
        <>
          <SaleAddDialog open={openAdd} setOpen={setOpenAdd} />
          <SaleEditDialog
            open={openEdit}
            setOpen={setOpenEdit}
            recordId={recordId}
          />
          <PrintHandler
            record={printRecord}
            resetPrintContent={resetPrintContent}
            Content={SJ}
          />
        </>
      )}
    </>
  );
};

export default SaleList;
