import { useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  BooleanField,
  BulkDeleteWithConfirmButton,
  NumberField,
  ReferenceField,
} from "react-admin";
import KindAddDialog from "../../components/kinds/create/KindAddDialog";
import KindEditDialog from "../../components/kinds/edit/KindEditDialog";
import kindFilterList from "../../components/kinds/filter/kindFilterList";
import EditBtn from "../../components/editBtn/EditBtn";
import TableListActions from "../../components/tableListActions/TableListActions";
import TooltipField from "../../components/common/TooltipField";

const KindList = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        actions={
          <TableListActions
            createHandler={handleClickOpenAdd}
            exporter={false}
          />
        }
        filters={kindFilterList}
        queryOptions={{ meta: { sort: ["created_at ASC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
        >
          <TextField source="name" label="mine.label.name" />
          <ReferenceField
            source="cost_of_goods_sold_id"
            reference="coa"
            emptyText="-"
          >
            <TooltipField source="name">
              <TextField source="code" label="Cost of Goods Sold" />
            </TooltipField>
          </ReferenceField>
          <ReferenceField source="sales_goods_id" reference="coa" emptyText="-">
            <TooltipField source="name">
              <TextField source="code" label="Sales Goods" />
            </TooltipField>
          </ReferenceField>
          <ReferenceField
            source="sales_return_good_id"
            reference="coa"
            emptyText="-"
          >
            <TooltipField source="name">
              <TextField source="code" label="Sales Return Good" />
            </TooltipField>
          </ReferenceField>
          <ReferenceField
            source="sales_return_broke_id"
            reference="coa"
            emptyText="-"
          >
            <TooltipField source="name">
              <TextField source="code" label="Sales Return Broke" />
            </TooltipField>
          </ReferenceField>
          <ReferenceField
            source="sales_return_complain_id"
            reference="coa"
            emptyText="-"
          >
            <TooltipField source="name">
              <TextField source="code" label="Sales Return Complain" />
            </TooltipField>
          </ReferenceField>
          <ReferenceField
            source="sales_discount_good_id"
            reference="coa"
            emptyText="-"
          >
            <TooltipField source="name">
              <TextField source="code" label="Sales Discount Good" />
            </TooltipField>
          </ReferenceField>
          <ReferenceField
            source="sales_discount_special_id"
            reference="coa"
            emptyText="-"
          >
            <TooltipField source="name">
              <TextField source="code" label="Sales Discount Special" />
            </TooltipField>
          </ReferenceField>
          <NumberField source="group" emptyText="-" />
          <BooleanField
            source="active"
            textAlign="center"
            label="mine.label.active"
          />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    []
  );
  return (
    <>
      {list}
      <KindAddDialog open={openAdd} setOpen={setOpenAdd} />
      <KindEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
    </>
  );
};

export default KindList;
