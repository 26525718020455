import { useEffect, useMemo } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  DateField,
  BooleanField,
  NumberField,
  // useTranslate,
  useTheme,
  usePermissions,
} from "react-admin";
import allTransactionJournalFilterList from "../../components/allTransactionJournals/filter/allTransactionJournalFilterList";
import AllTransactionJournalExpandList from "../../components/allTransactionJournals/expand/AllTransactionJournalExpandList";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const ExpandAll = () => {
  useEffect(() => {
    const expandAllBtn = document.querySelector(
      "table.RaDatagrid-table .RaDatagrid-thead .RaDatagrid-headerRow .RaDatagrid-expandHeader .RaDatagrid-expandIcon:not(.RaDatagrid-expanded)"
    );
    if (expandAllBtn) {
      var clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      expandAllBtn.dispatchEvent(clickEvent);
    }
  }, []);
  return null;
};

const AllTransactionJournalList = () => {
  // const t = useTranslate();
  const [theme] = useTheme();
  const { permissions } = usePermissions();

  const list = useMemo(
    () => (
      <List
        title="mine.list_title.all_transaction_journal"
        empty={false}
        actions={<DownloadAction />}
        filters={allTransactionJournalFilterList(permissions)}
        sort={{ field: "date", order: "ASC" }}
        filterDefaultValues={{
          start_date: firstDate,
          end_date: lastDate,
          is_balance: true,
        }}
        // queryOptions={{ meta: { sort: ["transactions.code ASC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={false}
          expand={<AllTransactionJournalExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          <ExpandAll />
          {permissions && permissions.update_company ? (
            <TextField source="company.alias" label="mine.tbl_header.company" />
          ) : null}
          <TextField source="code" label="mine.tbl_header.code" />
          <DateField source="date" label="mine.tbl_header.date" />
          <TextField source="cus_sup" label="mine.label.cus_sup" />
          <NumberField source="total_debit" />
          <NumberField source="total_credit" />
          <BooleanField
            source="is_balance"
            label="mine.label.balance"
            textAlign="center"
          />
        </DatagridConfigurable>
      </List>
    ),
    [theme]
  );
  return list;
};

export default AllTransactionJournalList;
