import { useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  DateField,
  FunctionField,
  useListContext,
  BulkDeleteWithConfirmButton,
  RecordContextProvider,
  ChipField,
  BooleanField,
  useResourceContext,
  useTheme,
  usePermissions,
} from "react-admin";
import BadDebtAddDialog from "../../components/badDebts/create/BadDebtAddDialog";
import BadDebtEditDialog from "../../components/badDebts/edit/BadDebtEditDialog";
import badDebtFilterList from "../../components/badDebts/filter/badDebtFilterList";
import TableListActions from "../../components/tableListActions/TableListActions";
import BadDebtExpandList from "../../components/badDebts/expand/BadDebtExpandList";
import ConfirmBtn from "../../components/confirmBtn/ConfirmBtn";
import EditBtn from "../../components/editBtn/EditBtn";
import DoneIcon from "@mui/icons-material/Done";
import Typography from "@mui/material/Typography";

const Aside = () => {
  const auy = useListContext();
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h6">Posts stats</Typography>
      <Typography variant="body2">
        Total views:{" "}
        {/* {ids.map((id) => data[id]).reduce((sum, post) => sum + post.views, 0)} */}
      </Typography>
    </div>
  );
};

const BadDebtList = () => {
  const resource = useResourceContext();
  const { permissions } = usePermissions();
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const [showAside, setShowAside] = useState(false);
  const [theme] = useTheme();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const ketemplag = (id, basePath, record) => {
    // console.log("Lets", id, basePath, record);
    // setShowAside((prev) => !prev);
  };
  const list = useMemo(
    () => (
      <List
        title="mine.list_title.bad_debt"
        empty={false}
        aside={showAside ? <Aside /> : null}
        actions={
          <TableListActions
            createHandler={handleClickOpenAdd}
            exporter={false}
          />
        }
        filters={badDebtFilterList(permissions)}
        queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={
            // openAdd || openEdit ? false :
            <BulkDeleteWithConfirmButton />
          }
          rowClick={ketemplag}
          expand={<BadDebtExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField label="mine.label.company" source="company.alias" />
          ) : null}
          <TextField source="code" label="mine.label.code" />
          <DateField source="date" label="mine.label.date" />
          <FunctionField
            label="mine.label.customer"
            render={(record) =>
              `${record.customer.name} (${record.customer.code})`
            }
          />
          <FunctionField
            label="mine.label.type"
            render={(record) => {
              let rec = {};
              if (record.type === 1) {
                rec.type = "Bad Debt";
                rec.color = "error";
              } else if (record.type === 2) {
                rec.type = "Cicilan";
                rec.color = "success";
              }
              return (
                <RecordContextProvider value={rec}>
                  <ChipField source="type" color={rec.color} size="small" />
                </RecordContextProvider>
              );
            }}
          />
          <BooleanField
            source="approved"
            label="mine.tbl_header.approved"
            textAlign="center"
          />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              render={(record) => {
                return (
                  !record.approved && (
                    <>
                      <EditBtn editHandler={handleClickOpenEdit(record.id)} />
                      <ConfirmBtn
                        title="mine.message.approve"
                        content="mine.message.approve_content"
                        label="mine.btn.approve"
                        resource={resource + "/approve"}
                        startIcon={<DoneIcon />}
                      />
                    </>
                  )
                );
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    [showAside, openAdd, openEdit, theme]
  );
  return (
    <>
      {list}
      <BadDebtAddDialog open={openAdd} setOpen={setOpenAdd} />
      <BadDebtEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
    </>
  );
};

export default BadDebtList;
