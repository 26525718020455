import { useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  DateField,
  BooleanField,
  BulkDeleteWithConfirmButton,
  FunctionField,
  useTranslate,
  useResourceContext,
  RecordContextProvider,
  ChipField,
  usePermissions,
} from "react-admin";
import BadDebtCheckCashingAddDialog from "../../components/badDebtCheckCashings/create/BadDebtCheckCashingAddDialog";
import BadDebtCheckCashingEditDialog from "../../components/badDebtCheckCashings/edit/BadDebtCheckCashingEditDialog";
import badDebtCheckCashingFilterList from "../../components/badDebtCheckCashings/filter/badDebtCheckCashingFilterList";
import TableListActions from "../../components/tableListActions/TableListActions";
import BadDebtCheckCashingExpandList from "../../components/badDebtCheckCashings/expand/BadDebtCheckCashingExpandList";
import EditBtn from "../../components/editBtn/EditBtn";
import ConfirmBtn from "../../components/confirmBtn/ConfirmBtn";
import DoneIcon from "@mui/icons-material/Done";

const BadDebtCheckCashingList = () => {
  const resource = useResourceContext();
  const { permissions } = usePermissions();
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const t = useTranslate();
  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        title="mine.list_title.bad_debt_check_cashing"
        empty={false}
        actions={
          <TableListActions
            createHandler={handleClickOpenAdd}
            exporter={false}
          />
        }
        filters={badDebtCheckCashingFilterList(permissions)}
        queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
          expand={<BadDebtCheckCashingExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: "#E7EBF0",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField source="company.alias" label="mine.tbl_header.company" />
          ) : null}
          <TextField source="code" label="mine.tbl_header.code" />
          <DateField source="date" label="mine.tbl_header.date" />
          <TextField
            source="bad_debt_installment.giro"
            label="mine.label.check_number"
          />
          <FunctionField
            label="mine.tbl_header.type"
            render={(record) => {
              let rec = {};
              if (record.type === 1) {
                rec.type = t("mine.label.cashing");
                rec.color = "primary";
              } else if (record.type === 2) {
                rec.type = t("mine.label.cancel");
                rec.color = "error";
              } else if (record.type === 3) {
                rec.type = t("mine.label.rejected");
                rec.color = "error";
              }
              return (
                <RecordContextProvider value={rec}>
                  <ChipField source="type" color={rec.color} size="small" />
                </RecordContextProvider>
              );
            }}
          />
          <TextField source="note" label="mine.tbl_header.note" />
          <BooleanField
            source="approved"
            label="mine.tbl_header.approved"
            textAlign="center"
          />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              render={(record) => {
                return (
                  !record.approved && (
                    <>
                      <EditBtn editHandler={handleClickOpenEdit(record.id)} />
                      <ConfirmBtn
                        title="mine.message.approve"
                        content="mine.message.approve_content"
                        label="mine.btn.approve"
                        resource={resource + "/approve"}
                        startIcon={<DoneIcon />}
                      />
                    </>
                  )
                );
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    []
  );
  return (
    <>
      {list}
      <BadDebtCheckCashingAddDialog open={openAdd} setOpen={setOpenAdd} />
      <BadDebtCheckCashingEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
    </>
  );
};

export default BadDebtCheckCashingList;
