import { forwardRef } from "react";
import Box from "@mui/material/Box";
import classes from "./PurchaseReturnPrint.module.css";
import Typography from "@mui/material/Typography";
import { DateField, NumberField, RecordContextProvider } from "react-admin";

const PurchaseReturnPrint = forwardRef((props, ref) => {
  // eslint-disable-line max-len
  // const { identity, isLoading: identityLoading } = useGetIdentity();

  let totalReceived = 0;
  return (
    <div
      style={{
        display: "none",
      }}
    >
      <Box ref={ref} sx={{ fontSize: "12px", py: 3, px: 4 }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              textTransform: "uppercase",
            }}
          >
            {props.record.company.name}
          </Typography>
          <Box>
            <Typography
              variant="subtitle2"
              component="span"
              sx={{
                pr: 1,
              }}
            >
              Print date:
            </Typography>
            <RecordContextProvider value={props.record}>
              <DateField
                source="print_date"
                sx={{ fontWeight: "inherit", fontSize: "12px" }}
              ></DateField>
            </RecordContextProvider>
          </Box>
        </Box>
        <Typography
          sx={{
            fontWeight: "bold",
            mb: 2,
            textTransform: "uppercase",
            borderBottom: "1px solid #000",
            textAlign: "center",
          }}
        >
          pecah keramik pembelian
        </Typography>
        <table className={classes.headerSj}>
          <thead>
            <tr>
              <th>No. Voucher</th>
              <th>:</th>
              <th>{props.record.code}</th>
              <th style={{ width: "50%" }}></th>
              <th>No. Order</th>
              <th>:</th>
              <th>
                {
                  props.record.purchase_return_items[0].purchase_item
                    .purchase_order_item.purchase_order.code
                }
              </th>
            </tr>
            <tr>
              <th>Tgl. Bukti</th>
              <th>:</th>
              <th>
                <RecordContextProvider value={props.record}>
                  <DateField
                    source="date"
                    sx={{ fontWeight: "inherit", fontSize: "12px" }}
                  ></DateField>
                </RecordContextProvider>
              </th>

              <th style={{ width: "50%" }}></th>
              <th>No. Penerimaan</th>
              <th>:</th>
              <th>
                {
                  props.record.purchase_return_items[0].purchase_item.purchase
                    .code
                }
              </th>
            </tr>
          </thead>
        </table>
        <Typography
          variant="body2"
          sx={{
            mt: 2,
            mb: 1,
          }}
        >
          Keterangan: {props.record.note}
        </Typography>
        <table className={classes.tableSj}>
          <thead>
            <tr>
              <th>No.</th>
              <th>Kode Barang</th>
              <th>Nama</th>
              <th>KW</th>
              <th style={{ textAlign: "right" }}>Kuantitas</th>
              <th style={{ textAlign: "right" }}>Harga Satuan</th>
              <th style={{ textAlign: "right" }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {props.record.purchase_return_items.map((obj, idx) => {
              totalReceived += obj.return_quantity;
              return (
                <tr key={obj.purchase_item.purchase_order_item.product.id}>
                  <td style={{ textAlign: "center" }}>{idx + 1}</td>
                  <td>{obj.purchase_item.purchase_order_item.product.code}</td>
                  <td>{obj.purchase_item.purchase_order_item.product.name}</td>
                  <td>
                    {obj.purchase_item.purchase_order_item.product.quality}
                  </td>
                  <td style={{ textAlign: "right" }}>{obj.return_quantity}</td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider
                      value={obj.purchase_item.purchase_order_item}
                    >
                      <NumberField
                        source="price"
                        sx={{ fontWeight: "inherit", fontSize: "12px" }}
                      ></NumberField>
                    </RecordContextProvider>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={obj}>
                      <NumberField
                        source="subtotal"
                        sx={{ fontWeight: "inherit", fontSize: "12px" }}
                      ></NumberField>
                    </RecordContextProvider>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan={3}
                style={{
                  textAlign: "right",
                  verticalAlign: "baseline",
                  fontWeight: "bold",
                  borderRight: "none",
                }}
              >
                Total:
              </td>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "baseline",
                  fontWeight: "bold",
                  borderLeft: "none",
                }}
              ></td>
              <td style={{ textAlign: "right" }}>{totalReceived}</td>
              <td></td>
              <td style={{ textAlign: "right" }}>
                <RecordContextProvider value={props.record}>
                  <NumberField
                    source="total"
                    sx={{ fontWeight: "inherit", fontSize: "12px" }}
                  ></NumberField>
                </RecordContextProvider>
              </td>
            </tr>
          </tfoot>
        </table>
        <Typography
          sx={{
            mt: 3,
            textTransform: "capitalize",
          }}
        >
          {"Terbilang: # " + props.record.total_in_words + " Rupiah #"}
        </Typography>
        <table style={{ marginTop: "8px" }} className={classes.tableACC}>
          <thead>
            <tr>
              <th colSpan={2}>Mengetahui</th>
              <th colSpan={2}>Pemeriksa</th>
              <th>Pelaksana</th>
            </tr>
            <tr>
              <th style={{ textTransform: "uppercase" }}>Akunting JKT</th>
              <th style={{ textTransform: "uppercase" }}>Kepala Depo</th>
              <th style={{ textTransform: "uppercase" }}>Akunting</th>
              <th style={{ textTransform: "uppercase" }}>Administrasi</th>
              <th style={{ textTransform: "uppercase" }}>Kepala Gudang</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ paddingTop: "80px" }}>(TATI K.)</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </Box>
    </div>
  );
});
export default PurchaseReturnPrint;
