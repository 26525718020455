import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";

const accountReceivableAgingFilterList = (permissions) => {
  const defaultFilter = [
    <DateInput
      alwaysOn
      source="start_date"
      variant="outlined"
      label="mine.label.start_date"
    />,
    <DateInput
      alwaysOn
      source="end_date"
      //FIXME: semua DateInput tidak bisa di tambah resettable
      // resettable
      variant="outlined"
      label="mine.label.end_date"
    />,
    <DateInput
      alwaysOn
      source="cut_off"
      variant="outlined"
      // label=""
    />,
    <ReferenceInput
      alwaysOn
      source="start_created_at"
      reference="customers"
      sort={{ field: "customers.created_at", order: "ASC" }}
      perPage={10}
    >
      <AutocompleteInput
        resettable
        optionValue="created_at"
        optionText="code"
        variant="outlined"
        label="mine.label.start_code"
      />
    </ReferenceInput>,
    <ReferenceInput
      alwaysOn
      source="end_created_at"
      reference="customers"
      sort={{ field: "customers.created_at", order: "ASC" }}
      perPage={10}
    >
      <AutocompleteInput
        resettable
        optionValue="created_at"
        optionText="code"
        variant="outlined"
        label="mine.label.end_code"
        // filterToQuery={(search) => ({ created_at: search })}
      />
    </ReferenceInput>,
    <SelectInput
      alwaysOn
      resettable
      source="kind"
      variant="outlined"
      label="mine.label.kind"
      choices={[
        { id: "per_surat_jalan", name: "Per Surat Jalan" },
        { id: "per_jatuh_tempo", name: "Per Jatuh Tempo" },
        { id: "per_salesman", name: "Per Salesman" },
        // { id: "overdue", name: "Overdue" },
        { id: "per_jenis", name: "Per Jenis" },
      ]}
    />,
  ];
  if (permissions && permissions.update_company) {
    defaultFilter.unshift(
      // defaultFilter.splice(
      // 1,
      // 0,
      <ReferenceInput
        alwaysOn
        source="company_id"
        reference="companies"
        sort={{ field: "companies.alias", order: "ASC" }}
      >
        <AutocompleteInput
          resettable
          optionText="alias"
          variant="outlined"
          label="mine.label.company"
        />
      </ReferenceInput>
    );
  }
  return defaultFilter;
};

export default accountReceivableAgingFilterList;
