import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const badDebtInstallmentFilterList = (permissions) => {
  const defaultFilter = [
    <TextInput
      source="all"
      label="mine.filter.search_all"
      alwaysOn
      resettable
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />,
    <TextInput
      alwaysOn
      source="code"
      label="mine.label.code"
      resettable
      variant="outlined"
    />,
    <DateInput
      alwaysOn
      source="date"
      label="mine.label.date"
      resettable
      variant="outlined"
    />,
    <ReferenceInput
      alwaysOn
      source="customer_id"
      reference="customers"
      label="mine.label.customer"
    >
      <AutocompleteInput
        optionText="name"
        variant="outlined"
        label="mine.label.customer"
      />
    </ReferenceInput>,
    <SelectInput
      source="type"
      alwaysOn
      variant="outlined"
      label="mine.label.type"
      resettable
      choices={[
        { id: 1, name: "Transfer" },
        { id: 2, name: "Cash" },
        { id: 3, name: "Giro" },
      ]}
    />,
    <TextInput
      alwaysOn
      source="note"
      label="mine.label.note"
      resettable
      variant="outlined"
    />,
  ];
  if (permissions && permissions.update_company) {
    defaultFilter.splice(
      1,
      0,
      <ReferenceInput
        alwaysOn
        source="company_id"
        reference="companies"
        sort={{ field: "companies.alias", order: "ASC" }}
      >
        <AutocompleteInput
          resettable
          optionText="alias"
          variant="outlined"
          label="mine.label.company"
        />
      </ReferenceInput>
    );
  }
  return defaultFilter;
};
export default badDebtInstallmentFilterList;
