import { useMemo } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  useTheme,
  NumberField,
  usePermissions,
} from "react-admin";
import inventoryCardFilterList from "../../components/inventoryCards/filter/inventoryCardFilterList";
import InventoryCardExpandList from "../../components/inventoryCards/expand/InventoryCardExpandList";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import InventoryCardAsideList from "../../components/inventoryCards/aside/InventoryCardAsideList";
import DownloadAction from "../../components/tableListActions/DownloadAction";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const InventoryCardList = () => {
  const { permissions } = usePermissions();
  const [theme] = useTheme();

  const list = useMemo(
    () => (
      <List
        storeKey={false} // Disabling Parameters Persistence
        title="mine.list_title.inventory_card"
        empty={false}
        aside={<InventoryCardAsideList />}
        actions={<DownloadAction />}
        filters={inventoryCardFilterList(permissions)}
        filterDefaultValues={{
          start_date: firstDate,
          end_date: lastDate,
        }}
        queryOptions={{ meta: { sort: ["products.code ASC"] } }} // overide default sort
        sx={{
          flexDirection: "column",
        }}
      >
        <DatagridConfigurable
          bulkActionButtons={false}
          // rowClick={rowClickHandler}
          expand={<InventoryCardExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          <TextField source="code" label="mine.label.product_code" />
          <NumberField source="initial" label="mine.label.initial" />
          <NumberField
            source="initial_value"
            label="mine.label.initial_value"
          />
          <NumberField source="total_in" label="mine.tbl_header.total_in" />
          <NumberField source="total_in_value" label="mine.label.value_in" />
          <NumberField source="total_out" label="mine.tbl_header.total_out" />
          <NumberField source="total_out_value" label="mine.label.value_out" />
          <NumberField source="other" label="mine.label.other" />
          <NumberField source="other_value" label="mine.label.other_value" />
          <NumberField source="final" label="mine.label.final" />
          <NumberField source="final_value" label="mine.label.final_value" />
        </DatagridConfigurable>
      </List>
    ),
    [theme, permissions]
  );

  return list;
};

export default InventoryCardList;
