import { forwardRef } from "react";
import Box from "@mui/material/Box";
import classes from "./FactoryDebtPaymentPrint.module.css";
import Typography from "@mui/material/Typography";
import { DateField, NumberField, RecordContextProvider } from "react-admin";

const FactoryDebtPaymentPrint = forwardRef((props, ref) => {
  // eslint-disable-line max-len
  // const { identity, isLoading: identityLoading } = useGetIdentity();

  let totalDebt = {total: 0};
  return (
    <div
      style={{
        display: "none",
      }}
    >
      <Box ref={ref} sx={{ fontSize: "12px", py: 3, px: 4 }}>
        <Typography
          sx={{
            fontWeight: "bold",
            mb: 2,
            textTransform: "uppercase",
            borderBottom: "1px solid #000",
            textAlign: "center",
          }}
        >
          bukti pembayaran hutang
        </Typography>
        <table className={classes.headerSj}>
          <thead>
            <tr>
              <th>Dibayarkan Kepada</th>
              <th>:</th>
              <th>{props.record.supplier.name}</th>
              <th style={{ width: "50%" }}></th>
              <th>Tanggal</th>
              <th>:</th>
              <th>
                <RecordContextProvider value={props.record}>
                  <DateField
                    source="date"
                    sx={{ fontWeight: "inherit", fontSize: "12px" }}
                  ></DateField>
                </RecordContextProvider>
              </th>
            </tr>
            <tr>
              <th>Tipe Bayar</th>
              <th>:</th>
              <th>{props.record.type === 1 ? "Transfer" : "Cash"}</th>
              <th style={{ width: "50%" }}></th>
              <th>No. Bukti</th>
              <th>:</th>
              <th>{props.record.code}</th>
            </tr>
          </thead>
        </table>
        <Typography
          variant="body2"
          sx={{
            mt: 2,
            mb: 1,
          }}
        >
          Keterangan: {props.record.note}
        </Typography>
        <table className={classes.tableSj}>
          <thead>
            <tr>
              <th>No. Transaksi</th>
              <th>Tgl. Transaksi</th>
              <th>Tgl. Jatuh Tempo</th>
              <th style={{ textAlign: "right" }}>Jumlah Tertagih</th>
              <th style={{ textAlign: "right" }}>Jumlah Bayar</th>
            </tr>
          </thead>
          <tbody>
            {props.record.factory_debt_payment_items.map((obj, idx) => {
              
              totalDebt.total += obj.purchase_order.company_debt.total;
              return (
                <tr key={obj.id}>
                  <td>{obj.purchase_order.code}</td>
                  <td>
                  <RecordContextProvider value={obj.purchase_order}>
                  <DateField
                    source="date"
                    sx={{ fontWeight: "inherit", fontSize: "12px" }}
                  ></DateField>
                </RecordContextProvider>
                  </td>
                  <td>
                  <RecordContextProvider value={obj.purchase_order.company_debt}>
                  <DateField
                    source="due_date"
                    sx={{ fontWeight: "inherit", fontSize: "12px" }}
                  ></DateField>
                </RecordContextProvider>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={obj.purchase_order.company_debt}>
                      <NumberField
                        source="total"
                        sx={{ fontWeight: "inherit", fontSize: "12px" }}
                      ></NumberField>
                    </RecordContextProvider>
                  </td>
                  <td style={{ textAlign: "right" }}>
                  <RecordContextProvider value={obj}>
                      <NumberField
                        source="amount"
                        sx={{ fontWeight: "inherit", fontSize: "12px" }}
                      ></NumberField>
                    </RecordContextProvider>
                  </td>
                  
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan={2}
                style={{
                  textAlign: "right",
                  verticalAlign: "baseline",
                  fontWeight: "bold",
                  borderRight: "none",
                }}
              >
                Total:
              </td>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "baseline",
                  fontWeight: "bold",
                  borderLeft: "none",
                }}
              ></td>
              <td style={{ textAlign: "right" }}>
              <RecordContextProvider value={totalDebt}>
                  <NumberField
                    source="total"
                    sx={{ fontWeight: "inherit", fontSize: "12px" }}
                  ></NumberField>
                </RecordContextProvider>
              </td>
              <td style={{ textAlign: "right" }}>
                <RecordContextProvider value={props.record}>
                  <NumberField
                    source="total"
                    sx={{ fontWeight: "inherit", fontSize: "12px" }}
                  ></NumberField>
                </RecordContextProvider>
              </td>
            </tr>
          </tfoot>
        </table>
        <Typography
          sx={{
            mt: 3,
            textTransform: "capitalize",
          }}
        >
          {"Terbilang: # " + props.record.total_in_words + " Rupiah #"}
        </Typography>
        <table style={{ marginTop: "8px" }} className={classes.tableACC}>
          <thead>
            <tr>
              <th>Mengetahui</th>
              <th colSpan={2}>Pelaksana</th>
            </tr>
            <tr>
              <th style={{ textTransform: "uppercase" }}>Kepala Depo</th>
              <th style={{ textTransform: "uppercase" }}>Akunting</th>
              <th style={{ textTransform: "uppercase" }}>Administrasi</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "80px"}}>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </div>
  );
});
export default FactoryDebtPaymentPrint;
