import { useMemo, useState } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  useTheme,
  usePermissions,
  NumberField,
} from "react-admin";
import accountReceivableCardFilterList from "../../components/accountReceivableCards/filter/accountReceivableCardFilterList";
import AccountReceivableCardExpandList from "../../components/accountReceivableCards/expand/AccountReceivableCardExpandList";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import DownloadAction from "../../components/tableListActions/DownloadAction";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const Aside = () => {
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <h5></h5>
    </div>
  );
};

const AccountReceivableCardList = () => {
  const { permissions } = usePermissions();
  const [showAside, setShowAside] = useState(false);
  const [theme] = useTheme();

  const rowClickHandler = (id, basePath, record) => {
    // console.log("Lets", id, basePath, record);
    setShowAside((prev) => !prev);
  };
  const list = useMemo(
    () => (
      <List
        title="mine.list_title.account_receivable_card"
        empty={false}
        aside={showAside ? <Aside /> : null}
        actions={<DownloadAction />}
        filters={accountReceivableCardFilterList(permissions)}
        sort={{ field: "customers.created_at", order: "ASC" }}
        filterDefaultValues={{
          start_date: firstDate,
          end_date: lastDate,
        }}
        // queryOptions={{ meta: { sort: ["customers.created_at ASC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={false}
          rowClick={rowClickHandler}
          expand={<AccountReceivableCardExpandList />}
          isRowExpandable={(row) => !!row.customer_debt_items.length}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField label="mine.label.company" source="company.alias" />
          ) : null}
          <TextField source="code" label="mine.label.customer_code" />
          <TextField source="name" label="mine.label.customer_name" />
          <NumberField source="initial" label="mine.label.initial_balance" />
          <NumberField source="debit" label="mine.label.debit" />
          <NumberField source="credit" label="mine.label.credit" />
          <NumberField source="final" label="mine.label.final_balance" />
        </DatagridConfigurable>
      </List>
    ),
    [showAside, theme]
  );
  return list;
};

export default AccountReceivableCardList;
