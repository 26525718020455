import { forwardRef } from "react";
import Box from "@mui/material/Box";
import classes from "./CustomerDebtPaymentPrint.module.css";
import Typography from "@mui/material/Typography";
import { DateField, NumberField, RecordContextProvider } from "react-admin";

const CustomerDebtPaymentPrint = forwardRef((props, ref) => {
  // eslint-disable-line max-len
  // const { identity, isLoading: identityLoading } = useGetIdentity();
  let paymentType = "";
  if (props.record.type === 1) {
    paymentType = "Transfer";
  } else if (props.record.type === 2) {
    paymentType = "Cash";
  } else if (props.record.type === 3) {
    paymentType = "Giro";
  }
  return (
    <div
      style={{
        display: "none",
      }}
    >
      <Box ref={ref} sx={{ fontSize: "12px", py: 3, px: 4 }}>
        <Typography
          sx={{
            fontWeight: "bold",
            mb: 2,
            textTransform: "uppercase",
            borderBottom: "1px solid #000",
            textAlign: "center",
          }}
        >
          bukti pembayaran piutang
        </Typography>
        <table className={classes.headerSj}>
          <thead>
            <tr>
              <th style={{ width: "75px", verticalAlign: "top" }}>
                Diterima dari
              </th>
              <th style={{ verticalAlign: "top" }}>:</th>
              <th style={{ verticalAlign: "top" }}>
                {props.record.customer.name}
              </th>
              <th></th>
              <th style={{ width: "57px", verticalAlign: "top" }}>Tanggal</th>
              <th style={{ verticalAlign: "top" }}>:</th>
              <th style={{ verticalAlign: "top" }}>
                <RecordContextProvider value={props.record}>
                  <DateField
                    source="date"
                    sx={{ fontWeight: "inherit", fontSize: "12px" }}
                  ></DateField>
                </RecordContextProvider>
              </th>
            </tr>
            <tr>
              <th style={{ verticalAlign: "top" }}>Tipe Bayar</th>
              <th style={{ verticalAlign: "top" }}>:</th>
              <th style={{ verticalAlign: "top" }}>{paymentType}</th>
              <th style={{ width: "20%" }}></th>
              <th style={{ verticalAlign: "top" }}>No. Bukti</th>
              <th style={{ verticalAlign: "top" }}>:</th>
              <th style={{ verticalAlign: "top", width: "100px" }}>
                {props.record.code}
              </th>
            </tr>
          </thead>
        </table>
        <Typography
          variant="body2"
          sx={{
            mt: 2,
            mb: 1,
          }}
        >
          Keterangan: {props.record.note}
        </Typography>
        <table className={classes.tableSj}>
          <thead>
            <tr>
              <th>No. Transaksi</th>
              <th>Tgl. Transaksi</th>
              <th>Tgl. Jatuh Tempo</th>
              <th style={{ textAlign: "right" }}>Jumlah Tagihan</th>
              <th style={{ textAlign: "right" }}>Jumlah Bayar</th>
            </tr>
          </thead>
          <tbody>
            {props.record.customer_debt_payment_items.map((obj, idx) => {
              return (
                <tr key={obj.id}>
                  <td>{obj.sale.code}</td>
                  <td>
                    <RecordContextProvider value={obj.sale}>
                      <DateField
                        source="date"
                        sx={{ fontWeight: "inherit", fontSize: "12px" }}
                      ></DateField>
                    </RecordContextProvider>
                  </td>
                  <td>
                    <RecordContextProvider value={obj.sale.customer_debt}>
                      <DateField
                        source="due_date"
                        sx={{ fontWeight: "inherit", fontSize: "12px" }}
                      ></DateField>
                    </RecordContextProvider>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={obj.sale.customer_debt}>
                      <NumberField
                        source="total"
                        sx={{ fontWeight: "inherit", fontSize: "12px" }}
                      ></NumberField>
                    </RecordContextProvider>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={obj}>
                      <NumberField
                        source="amount"
                        sx={{ fontWeight: "inherit", fontSize: "12px" }}
                      ></NumberField>
                    </RecordContextProvider>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan={4}
                style={{
                  textAlign: "right",
                  verticalAlign: "baseline",
                  fontWeight: "bold",
                  borderRight: "none",
                }}
              >
                Total:
              </td>
              <td style={{ textAlign: "right" }}>
                <RecordContextProvider value={props.record}>
                  <NumberField
                    source="total"
                    sx={{ fontWeight: "inherit", fontSize: "12px" }}
                  ></NumberField>
                </RecordContextProvider>
              </td>
            </tr>
          </tfoot>
        </table>
        <Typography
          sx={{
            mt: 3,
            textTransform: "capitalize",
          }}
        >
          {"Terbilang: # " + props.record.total_in_words + " Rupiah #"}
        </Typography>
        <table style={{ marginTop: "8px" }} className={classes.tableACC}>
          <thead>
            <tr>
              <th>Mengetahui</th>
              <th colSpan={2}>Pelaksana</th>
            </tr>
            <tr>
              <th style={{ textTransform: "uppercase" }}>Kepala Depo</th>
              <th style={{ textTransform: "uppercase" }}>Akunting</th>
              <th style={{ textTransform: "uppercase" }}>Finance</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "80px" }}></td>
            </tr>
          </tbody>
        </table>
      </Box>
    </div>
  );
});
export default CustomerDebtPaymentPrint;
