import { useMemo } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  useTheme,
  NumberField,
  usePermissions,
  useTranslate,
} from "react-admin";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import AccountReceivableAgingExpandList from "../../components/accountReceivableAgings/expand/AccountReceivableAgingExpandList";
import accountReceivableAgingFilterList from "../../components/accountReceivableAgings/filter/accountReceivableAgingFilterList";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const AccountReceivableAgingList = () => {
  const { permissions } = usePermissions();
  const [theme] = useTheme();
  const t = useTranslate();

  const list = useMemo(
    () => (
      <List
        storeKey={false} // Disabling Parameters Persistence
        title="mine.list_title.account_receivable_aging"
        empty={false}
        // aside={<InventoryCardAsideList />}
        actions={<DownloadAction />}
        filters={accountReceivableAgingFilterList(permissions)}
        filterDefaultValues={{
          start_date: firstDate,
          end_date: lastDate,
          cut_off: lastDate,
          kind: "per_surat_jalan",
        }}
        queryOptions={{ meta: { sort: ["customers.code ASC"] } }} // overide default sort
        sx={{
          flexDirection: "column",
        }}
      >
        <DatagridConfigurable
          bulkActionButtons={false}
          // rowClick={rowClickHandler}
          isRowExpandable={(row) => !!row.customer_debts || !!row.sale_orders} // sale_orders jika jenisnya per salesman
          expand={<AccountReceivableAgingExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          <TextField source="code" label="mine.label.code" />
          <TextField source="name" label="mine.label.name" />
          <NumberField source="total_ar" label="mine.label.customer_debt" />
          <NumberField source="total_overdue" label="Overdue" />
          <NumberField
            source="total_less_30"
            label={"< 30 " + t("mine.label.day")}
          />
          <NumberField
            source="total_between_30_60"
            label={"30 - 60 " + t("mine.label.day")}
          />
          <NumberField
            source="total_between_61_90"
            label={"61 - 90 " + t("mine.label.day")}
          />
          <NumberField
            source="total_between_91_120"
            label={"91 - 120 " + t("mine.label.day")}
          />
          <NumberField
            source="total_between_121_150"
            label={"121 - 150 " + t("mine.label.day")}
          />
          <NumberField
            source="total_above_150"
            label={"> 150 " + t("mine.label.day")}
          />
          {/* <NumberField
            source="initial_value"
            label="mine.label.initial_value"
          />
          <NumberField source="total_in_value" label="mine.label.value_in" />
          <NumberField source="total_out" label="mine.tbl_header.total_out" />
          <NumberField source="total_out_value" label="mine.label.value_out" />
          <NumberField source="other" label="mine.label.other" />
          <NumberField source="other_value" label="mine.label.other_value" />
          <NumberField source="final" label="mine.label.final" />
          <NumberField source="final_value" label="mine.label.final_value" /> */}
        </DatagridConfigurable>
      </List>
    ),
    [theme, permissions]
  );

  return list;
};

export default AccountReceivableAgingList;
