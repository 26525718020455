import * as React from "react";
import {
  useResourceContext,
  useDataProvider,
  Error,
  RecordContextProvider,
  NumberField,
  DatagridConfigurable,
  useTheme,
  TextField,
  useListContext,
  useTranslate,
} from "react-admin";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useQuery } from "react-query";

const DatagridHeader = ({ children }) => {
  const t = useTranslate();
  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        {/* empty cell to account for the select row checkbox in the body */}
        {React.Children.map(children, (child) => {
          return React.isValidElement(child) ? (
            <TableCell key={child.props.source} align={child.props.textAlign}>
              {child.props.label ? t(child.props.label) : ""}
            </TableCell>
          ) : null;
        })}
      </TableRow>
    </TableHead>
  );
};

const InventoryCardAsideList = () => {
  const resource = useResourceContext();
  const { filterValues } = useListContext();

  const [theme] = useTheme();

  let ifCtx;
  if (Object.keys(filterValues).length) {
    ifCtx = filterValues;
  }

  const resourceFilter =
    resource +
    "/grand-total" +
    (ifCtx ? "?filter=" + encodeURIComponent(JSON.stringify(ifCtx)) : "");

  const dataProvider = useDataProvider();
  const { data, isLoading, error } = useQuery(
    [resourceFilter, "getListNoParams"],
    () => dataProvider.getListNoParams(resourceFilter)
  );

  if (error) return <Error />;

  return (
    <Card
      sx={{
        mt: 1,
        position: "sticky",
        bottom: 0,
        alignSelf: "stretch",
      }}
    >
      <CardContent sx={{ ":last-child": { p: 0 } }}>
        <DatagridConfigurable
          header={<DatagridHeader />}
          data={data}
          // total={1}
          isLoading={isLoading}
          bulkActionButtons={false}
          isRowExpandable={() => false}
          expand={<span></span>}
          isRowSelectable={() => false}
          hover={false}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          <RecordContextProvider value={{ name: "GRAND TOTAL" }}>
            <TextField source="name" />
          </RecordContextProvider>
          <NumberField
            source="initial"
            label="mine.label.initial"
            textAlign="right"
          />
          <NumberField
            source="initial_value"
            label="mine.label.initial_value"
            textAlign="right"
          />
          <NumberField
            source="total_in"
            label="mine.tbl_header.total_in"
            textAlign="right"
          />
          <NumberField
            source="total_in_value"
            label="mine.label.value_in"
            textAlign="right"
          />
          <NumberField
            source="total_out"
            label="mine.tbl_header.total_out"
            textAlign="right"
          />
          <NumberField
            source="total_out_value"
            label="mine.label.value_out"
            textAlign="right"
          />
          <NumberField
            source="other"
            label="mine.label.other"
            textAlign="right"
          />
          <NumberField
            source="other_value"
            label="mine.label.other_value"
            textAlign="right"
          />
          <NumberField
            source="final"
            label="mine.label.final"
            textAlign="right"
          />
          <NumberField
            source="final_value"
            label="mine.label.final_value"
            textAlign="right"
          />
        </DatagridConfigurable>
      </CardContent>
    </Card>
  );
};
export default InventoryCardAsideList;
