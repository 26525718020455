import {
  useResourceContext,
  useDataProvider,
  Error,
  RecordContextProvider,
  NumberField,
  TextField,
  useListContext,
  Labeled,
} from "react-admin";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { useQuery } from "react-query";

const PurchaseNetCardAsideList = ({ name, filter = {} }) => {
  const resource = useResourceContext();
  const { filterValues } = useListContext();

  let ifCtx;
  if (Object.keys(filterValues).length) {
    ifCtx = { ...filterValues, ...filter, approved: 1 };
  }

  const resourceFilter =
    resource +
    "/grand-total-gross" +
    (ifCtx ? "?filter=" + encodeURIComponent(JSON.stringify(ifCtx)) : "");

  const dataProvider = useDataProvider();
  const { data, error } = useQuery([resourceFilter, "getListNoParams"], () =>
    dataProvider.getListNoParams(resourceFilter)
  );

  if (error) return <Error />;

  if (!data) {
    return null;
  }
  if (data.length < 1) {
    return null;
  }

  return (
    <>
      <Card
        sx={{
          mt: 1,
          position: "sticky",
          bottom: 0,
          alignSelf: "stretch",
        }}
      >
        <CardContent sx={{ ":last-child": { p: 0 } }}>
          <Box
            component="table"
            sx={{
              width: "100%",
              "& td, th": {
                px: "10px",
              },
              "& td .MuiTypography-root": {
                fontWeight: "bold",
              },
              "& th": {
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              },
            }}
          >
            <tbody>
              <tr>
                <td style={{ width: "50%" }}>
                  <RecordContextProvider value={{ name }}>
                    <TextField source="name" />
                  </RecordContextProvider>
                </td>
                <td style={{ textAlign: "right", width: "25%" }}>
                  <RecordContextProvider value={data[0]}>
                    <Labeled>
                      <NumberField source="qty" label="Box" textAlign="right" />
                    </Labeled>
                  </RecordContextProvider>
                </td>
                <td style={{ textAlign: "right", width: "25%" }}>
                  <RecordContextProvider value={data[0]}>
                    <Labeled>
                      <NumberField
                        source="value"
                        label="mine.label.value"
                        textAlign="right"
                      />
                    </Labeled>
                  </RecordContextProvider>
                </td>
              </tr>
            </tbody>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
export default PurchaseNetCardAsideList;
