import { forwardRef } from "react";
import Box from "@mui/material/Box";
import classes from "./PJ.module.css";
import Typography from "@mui/material/Typography";
import { DateField, NumberField, RecordContextProvider } from "react-admin";

const PJ = forwardRef((props, ref) => {
  // eslint-disable-line max-len
  // const { identity, isLoading: identityLoading } = useGetIdentity();

  let totalReceived = 0;
  return (
    <div
      style={{
        display: "none",
      }}
    >
      <Box ref={ref} sx={{ fontSize: "12px", py: 3, px: 4 }}>
        <Typography
          sx={{
            fontWeight: "bold",
            mb: 2,
            textTransform: "uppercase",
            borderBottom: "1px solid #000",
            textAlign: "center",
          }}
        >
          invoice pembelian keramik
        </Typography>
        <table className={classes.headerSj}>
          <thead>
            <tr>
              <th>No. Bukti</th>
              <th>:</th>
              <th>{props.record.code}</th>
              <th style={{ width: "50%" }}></th>
              <th>No. Order</th>
              <th>:</th>
              <th>
                {
                  props.record.purchase_items[0].purchase_order_item
                    .purchase_order.code
                }
              </th>
            </tr>
            <tr>
              <th>Tgl. Bukti</th>
              <th>:</th>
              <th>
                <RecordContextProvider value={props.record}>
                  <DateField
                    source="date"
                    sx={{ fontWeight: "inherit", fontSize: "12px" }}
                  ></DateField>
                </RecordContextProvider>
              </th>
              <th style={{ width: "50%" }}></th>
              <th>Nama Pemasok</th>
              <th>:</th>
              <th>{props.record.supplier.name}</th>
            </tr>
          </thead>
        </table>

        <table className={classes.tableSj}>
          <thead>
            <tr>
              <th>No.</th>
              <th>Kode Barang</th>
              <th>Ukuran</th>
              <th style={{ textAlign: "right" }}>Qty Pesan</th>
              <th style={{ textAlign: "right" }}>Qty Terima</th>
              <th style={{ textAlign: "right" }}>Harga Satuan</th>
              <th style={{ textAlign: "right" }}>Total</th>
              <th>Lokasi</th>
            </tr>
          </thead>
          <tbody>
            {props.record.purchase_items.map((obj, idx) => {
              totalReceived += obj.received_quantity;
              return (
                <tr key={obj.purchase_order_item.product.id}>
                  <td style={{ textAlign: "center" }}>{idx + 1}</td>
                  <td>{obj.purchase_order_item.product.code}</td>
                  <td>
                    {obj.purchase_order_item.product.size +
                      " " +
                      obj.purchase_order_item.product.quality}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {obj.received_quantity}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {obj.received_quantity}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={obj.purchase_order_item}>
                      <NumberField
                        source="price"
                        sx={{ fontWeight: "inherit", fontSize: "12px" }}
                      ></NumberField>
                    </RecordContextProvider>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={obj}>
                      <NumberField
                        source="subtotal"
                        sx={{ fontWeight: "inherit", fontSize: "12px" }}
                      ></NumberField>
                    </RecordContextProvider>
                  </td>
                  <td>{obj.warehouse.name}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan={3}
                style={{
                  textAlign: "right",
                  verticalAlign: "baseline",
                  fontWeight: "bold",
                  borderRight: "none",
                }}
              >
                Total:
              </td>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "baseline",
                  fontWeight: "bold",
                  borderLeft: "none",
                }}
              ></td>
              <td style={{ textAlign: "right" }}>{totalReceived}</td>
              <td></td>
              <td style={{ textAlign: "right" }}>
                <RecordContextProvider value={props.record}>
                  <NumberField
                    source="total"
                    sx={{ fontWeight: "inherit", fontSize: "12px" }}
                  ></NumberField>
                </RecordContextProvider>
              </td>
              <td></td>
            </tr>
          </tfoot>
        </table>
        <Typography
          sx={{
            mt: 3,
            textTransform: "capitalize",
          }}
        >
          {"Terbilang: # " + props.record.total_in_words + " Rupiah #"}
        </Typography>
        <table style={{ marginTop: "8px" }} className={classes.tableACC}>
          <thead>
            <tr>
              <th>Mengetahui</th>
              <th colSpan={2}>Pemeriksa</th>
              <th>Pelaksana</th>
            </tr>
            <tr>
              <th style={{ textTransform: "uppercase" }}>Manager Sales</th>
              <th style={{ textTransform: "uppercase" }}>Akunting</th>
              <th style={{ textTransform: "uppercase" }}>Administrasi</th>
              <th style={{ textTransform: "uppercase" }}>Kepala Gudang</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ padding: "40px 0" }}></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </Box>
    </div>
  );
});
export default PJ;
