import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";

const purchaseNetCardFilterList = (permissions) => {
  const defaultFilter = [
    <DateInput
      alwaysOn
      source="start_date"
      label="mine.label.start_date"
      resettable
      variant="outlined"
    />,
    <DateInput
      alwaysOn
      source="end_date"
      label="mine.label.end_date"
      resettable
      variant="outlined"
    />,
    <ReferenceInput alwaysOn source="supplier_id" reference="suppliers">
      <SelectInput
        optionText="name"
        resettable
        variant="outlined"
        label="mine.label.supplier"
      />
    </ReferenceInput>,
    <TextInput
      alwaysOn
      source="delivery_note"
      label="mine.label.delivery_note"
      resettable
      variant="outlined"
    />,
    <ReferenceInput alwaysOn source="quality_id" reference="qualities">
      <AutocompleteInput
        label="mine.label.quality"
        variant="outlined"
        fullWidth
        optionValue="id"
        optionText="name"
        filterToQuery={(searchText) => ({
          name: searchText,
        })}
      />
    </ReferenceInput>,
    <ReferenceInput alwaysOn source="size_id" reference="sizes">
      <AutocompleteInput
        label="mine.label.size"
        variant="outlined"
        fullWidth
        optionValue="id"
        optionText="name"
        filterToQuery={(searchText) => ({
          name: searchText,
        })}
      />
    </ReferenceInput>,
  ];
  if (permissions && permissions.update_company) {
    defaultFilter.splice(
      1,
      0,
      <ReferenceInput
        alwaysOn
        source="company_id"
        reference="companies"
        sort={{ field: "companies.alias", order: "ASC" }}
      >
        <AutocompleteInput
          resettable
          optionText="alias"
          variant="outlined"
          label="mine.label.company"
        />
      </ReferenceInput>
    );
  }
  return defaultFilter;
};
export default purchaseNetCardFilterList;
