import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import {
  useCreate,
  useNotify,
  SimpleForm,
  TextInput,
  Create,
  useResourceContext,
  required,
  maxLength,
  useRefresh,
  BooleanInput,
  number,
  useTranslate,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  minLength,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import {
  OptionRenderer,
  filterToQuery,
  inputText,
  matchSuggestion,
} from "../../customerDebtPayments/common/CustomSelectOption";

const validateName = [required(), maxLength(100)];
const validateCode = [required(), maxLength(36), minLength(36)];
const validateGroup = [required(), number()];
const validateActive = [required()];

export default function KindAddDialog({ open, setOpen }) {
  const [create] = useCreate();
  const notify = useNotify();
  const resource = useResourceContext(); // cuman nama resource
  const refresh = useRefresh();
  const t = useTranslate();

  const save = useCallback(
    async (values) => {
      try {
        await create(
          resource,
          { data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.created", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [create]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted={false}
      fullWidth={true}
      maxWidth="lg"
    >
      <Create title={" "} sx={{ "& .RaCreate-main": { mt: 0 } }}>
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>{t("mine.dialog.create_kind")}</DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent sx={{ alignSelf: "normal" }}>
            <TextInput
              variant="outlined"
              label="mine.label.name"
              source="name"
              fullWidth
              validate={validateName}
              autoFocus={true}
            />
            <NumberInput
              variant="outlined"
              source="group"
              fullWidth
              validate={validateGroup}
              helperText="Diisi nomer brapapun, yang sama artinya bisa satu SO/SJ"
            />

            <BooleanInput
              variant="outlined"
              source="active"
              fullWidth
              validate={validateActive}
              defaultValue={true}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <ReferenceInput
                  source="cost_of_goods_sold_id"
                  reference="coa"
                  perPage={10}
                  sort={{ field: "code", order: "ASC" }}
                  enableGetChoices={(filters) => !!filters.all}
                >
                  <AutocompleteInput
                    label="COA Cost of Goods Sold"
                    variant="outlined"
                    fullWidth
                    validate={validateCode}
                    optionValue="id"
                    // optionText="code"
                    optionText={<OptionRenderer />}
                    inputText={inputText}
                    matchSuggestion={matchSuggestion}
                    filterToQuery={filterToQuery}
                    helperText={false}
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} md={6}>
                <ReferenceInput
                  source="sales_goods_id"
                  reference="coa"
                  perPage={10}
                  sort={{ field: "code", order: "ASC" }}
                  enableGetChoices={(filters) => !!filters.all}
                >
                  <AutocompleteInput
                    label="COA Sales Goods"
                    variant="outlined"
                    fullWidth
                    validate={validateCode}
                    optionValue="id"
                    // optionText="code"
                    optionText={<OptionRenderer />}
                    inputText={inputText}
                    matchSuggestion={matchSuggestion}
                    filterToQuery={filterToQuery}
                    helperText={false}
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} md={6}>
                <ReferenceInput
                  source="sales_return_good_id"
                  reference="coa"
                  perPage={10}
                  sort={{ field: "code", order: "ASC" }}
                  enableGetChoices={(filters) => !!filters.all}
                >
                  <AutocompleteInput
                    label="COA Sales Return Good"
                    variant="outlined"
                    fullWidth
                    validate={validateCode}
                    optionValue="id"
                    // optionText="code"
                    optionText={<OptionRenderer />}
                    inputText={inputText}
                    matchSuggestion={matchSuggestion}
                    filterToQuery={filterToQuery}
                    helperText={false}
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} md={6}>
                <ReferenceInput
                  source="sales_return_broke_id"
                  reference="coa"
                  perPage={10}
                  sort={{ field: "code", order: "ASC" }}
                  enableGetChoices={(filters) => !!filters.all}
                >
                  <AutocompleteInput
                    label="COA Sales Return Broke"
                    variant="outlined"
                    fullWidth
                    validate={validateCode}
                    optionValue="id"
                    // optionText="code"
                    optionText={<OptionRenderer />}
                    inputText={inputText}
                    matchSuggestion={matchSuggestion}
                    filterToQuery={filterToQuery}
                    helperText={false}
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} md={6}>
                <ReferenceInput
                  source="sales_return_complain_id"
                  reference="coa"
                  perPage={10}
                  sort={{ field: "code", order: "ASC" }}
                  enableGetChoices={(filters) => !!filters.all}
                >
                  <AutocompleteInput
                    label="COA Sales Return Complain"
                    variant="outlined"
                    fullWidth
                    validate={validateCode}
                    optionValue="id"
                    // optionText="code"
                    optionText={<OptionRenderer />}
                    inputText={inputText}
                    matchSuggestion={matchSuggestion}
                    filterToQuery={filterToQuery}
                    helperText={false}
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} md={6}>
                <ReferenceInput
                  source="sales_discount_good_id"
                  reference="coa"
                  perPage={10}
                  sort={{ field: "code", order: "ASC" }}
                  enableGetChoices={(filters) => !!filters.all}
                >
                  <AutocompleteInput
                    label="COA Sales Discount Good"
                    variant="outlined"
                    fullWidth
                    validate={validateCode}
                    optionValue="id"
                    // optionText="code"
                    optionText={<OptionRenderer />}
                    inputText={inputText}
                    matchSuggestion={matchSuggestion}
                    filterToQuery={filterToQuery}
                    helperText={false}
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} md={6}>
                <ReferenceInput
                  source="sales_discount_special_id"
                  reference="coa"
                  perPage={10}
                  sort={{ field: "code", order: "ASC" }}
                  enableGetChoices={(filters) => !!filters.all}
                >
                  <AutocompleteInput
                    label="COA Sales Discount Special"
                    variant="outlined"
                    fullWidth
                    validate={validateCode}
                    optionValue="id"
                    // optionText="code"
                    optionText={<OptionRenderer />}
                    inputText={inputText}
                    matchSuggestion={matchSuggestion}
                    filterToQuery={filterToQuery}
                    helperText={false}
                  />
                </ReferenceInput>
              </Grid>
            </Grid>
          </DialogContent>
        </SimpleForm>
      </Create>
    </Dialog>
  );
}
