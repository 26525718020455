import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  TextInput,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const factoryDebtCorrectionFilterList = (permissions) => {
  const defaultFilter = [
    <TextInput
      source="all"
      label="mine.filter.search_all"
      alwaysOn
      resettable
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />,
    <TextInput
      alwaysOn
      source="code"
      label="mine.label.code"
      resettable
      variant="outlined"
    />,
    <DateInput
      alwaysOn
      source="date"
      label="mine.label.date"
      resettable
      variant="outlined"
    />,
    <ReferenceInput
      label="Supplier"
      source="supplier_id"
      reference="suppliers"
      alwaysOn
    >
      <AutocompleteInput optionText="name" resettable variant="outlined" />
    </ReferenceInput>,
    <TextInput
      alwaysOn
      source="note"
      label="mine.label.note"
      resettable
      variant="outlined"
    />,
  ];
  if (permissions && permissions.update_company) {
    defaultFilter.splice(
      1,
      0,
      <ReferenceInput
        alwaysOn
        source="company_id"
        reference="companies"
        sort={{ field: "companies.alias", order: "ASC" }}
      >
        <AutocompleteInput
          resettable
          optionText="alias"
          variant="outlined"
          label="mine.label.company"
        />
      </ReferenceInput>
    );
  }
  return defaultFilter;
};
export default factoryDebtCorrectionFilterList;
