import * as React from "react";
import {
  useResourceContext,
  useDataProvider,
  Error,
  RecordContextProvider,
  NumberField,
  DatagridConfigurable,
  useTheme,
  TextField,
  useListContext,
  useTranslate,
} from "react-admin";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useQuery } from "react-query";

// const DatagridHeader = ({ children }) => {
//   const t = useTranslate();
//   return (
//     <TableHead>
//       <TableRow>
//         <TableCell></TableCell>
//         {/* empty cell to account for the select row checkbox in the body */}
//         {React.Children.map(children, (child) => {
//           return React.isValidElement(child) ? (
//             <TableCell key={child.props.source} align={child.props.textAlign}>
//               {child.props.label ? t(child.props.label) : ""}
//             </TableCell>
//           ) : null;
//         })}
//       </TableRow>
//     </TableHead>
//   );
// };

const PurchaseAsideList = () => {
  const resource = useResourceContext();
  const { filterValues } = useListContext();
  const t = useTranslate();

  // const [theme] = useTheme();

  let ifCtx;
  if (Object.keys(filterValues).length) {
    ifCtx = { ...filterValues, approved: 1 };
  }

  const resourceFilter =
    resource +
    "/grand-total" +
    (ifCtx ? "?filter=" + encodeURIComponent(JSON.stringify(ifCtx)) : "");

  const dataProvider = useDataProvider();
  const { data, isLoading, error } = useQuery(
    [resourceFilter, "getListNoParams"],
    () => dataProvider.getListNoParams(resourceFilter)
  );

  if (error) return <Error />;

  if (!data) {
    return null;
  }
  if (data.length < 1) {
    return null;
  }

  return (
    <Card
      sx={{
        mt: 1,
        position: "sticky",
        bottom: 0,
        alignSelf: "stretch",
      }}
    >
      <CardContent sx={{ ":last-child": { p: 0 } }}>
        <Box
          component="table"
          sx={{
            width: "100%",
            "& td, th": {
              p: "10px",
            },
            "& th": {
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            },
          }}
        >
          <thead>
            <tr>
              <th></th>
              <th style={{ textAlign: "right" }}>
                <RecordContextProvider value={{ name: "Box" }}>
                  <TextField
                    source="name"
                    sx={{
                      fontWeight: 500,
                    }}
                  />
                </RecordContextProvider>
              </th>
              <th style={{ textAlign: "right" }}>
                <RecordContextProvider value={{ name: t("mine.label.value") }}>
                  <TextField
                    source="name"
                    sx={{
                      fontWeight: 500,
                    }}
                  />
                </RecordContextProvider>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <RecordContextProvider value={{ name: "GRAND TOTAL" }}>
                  <TextField source="name" />
                </RecordContextProvider>
              </td>
              <td style={{ textAlign: "right" }}>
                <RecordContextProvider value={data[0]}>
                  <NumberField
                    source="qty"
                    label="mine.label.quantity"
                    textAlign="right"
                  />
                </RecordContextProvider>
              </td>
              <td style={{ textAlign: "right" }}>
                <RecordContextProvider value={data[0]}>
                  <NumberField
                    source="value"
                    label="mine.label.value"
                    textAlign="right"
                  />
                </RecordContextProvider>
              </td>
            </tr>
          </tbody>
        </Box>
      </CardContent>
    </Card>
  );
};
export default PurchaseAsideList;
