// import { useEffect, useState } from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  // useDataProvider,
} from "react-admin";
import { useWatch } from "react-hook-form";
// import { useFormContext } from "react-hook-form";
import {
  SaleOptionRenderer,
  saleFilterToQuery,
  saleInputText,
  saleMatchSuggestion,
} from "./SaleCustomSelectOption";

const SaleDependOnCustomer = (props) => {
  // const [choices, setChoices] = useState([]);
  // const dataProvider = useDataProvider();
  const dependOn = useWatch({ name: "customer_id" });
  // const { setValue } = useFormContext();
  // useEffect(() => {
  //   if (dependOn) {
  //     (async () => {
  //       const data = await dataProvider.getListNoParams(
  //         "sales/select?customer_id=" + dependOn
  //       );
  //       setChoices(data);
  //     })();
  //   }
  //   setValue(props.source, undefined);
  //   setChoices([]);
  // }, [dependOn, props.source]);
  return (
    <ReferenceInput
      source={props.source}
      reference="sales"
      perPage={10}
      sort={{ field: "sales.code", order: "ASC" }}
      filter={{
        "CustomerDebt.customer_id": dependOn,
        do_over_due_check: 1,
      }}
      enableGetChoices={(q) => !!dependOn}
    >
      <AutocompleteInput
        {...props}
        optionText={<SaleOptionRenderer />}
        inputText={saleInputText}
        matchSuggestion={saleMatchSuggestion}
        filterToQuery={saleFilterToQuery}
        helperText="mine.message.over_due_debt"
      />
    </ReferenceInput>
  );
};

export default SaleDependOnCustomer;
