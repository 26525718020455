import { memo, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import PrintContent from "../sales/print/SJ";
import { useResourceContext, useTranslate } from "react-admin";

const PrintHandler = ({ record, resetPrintContent, Content }) => {
  const componentRef = useRef(null);
  const resource = useResourceContext();
  const t = useTranslate();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: t("mine.dialog.print") + " " + resource.toUpperCase(),
    removeAfterPrint: true,
    onAfterPrint: resetPrintContent ? resetPrintContent : undefined,
  });

  useEffect(() => {
    if (record.id) {
      handlePrint();
    }
  }, [record.id, handlePrint]);

  return record.id ? <Content ref={componentRef} record={record} /> : null;
};

// penting pake ini karena tanpa ini dialog print
// ikut muncul setiap buka dialog create / update
const propsAreEqual = (prevProps, nextProps) => {
  if (prevProps.record.id === nextProps.record.id) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};

export default memo(PrintHandler, propsAreEqual);
