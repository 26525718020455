import {
  useRecordContext,
  RecordContextProvider,
  NumberField,
  TabbedShowLayout,
  useTranslate,
} from "react-admin";
import Box from "@mui/material/Box";

const SaleNetCardExpandList = () => {
  const record = useRecordContext();
  const t = useTranslate();
  return (
    <TabbedShowLayout
      syncWithLocation={false}
      sx={{
        m: 5,
        backgroundClip: "padding-box",
        boxShadow: (theme) => {
          return theme.components.MuiPaper.styleOverrides.elevation1.boxShadow;
        },
        borderRadius: "4px",
        bgcolor: "background.paper",
      }}
    >
      <TabbedShowLayout.Tab label={t("mine.tbl_header.product")}>
        <Box
          component="table"
          sx={{
            width: "100%",
            // bgcolor: "rgba(0,0,0,.04)",
            fontSize: "smaller",
            borderCollapse: "collapse",
            "& th, td": {
              padding: "6px 8px",
              border: "1px solid #dddddd",
            },
          }}
        >
          <thead>
            <tr>
              <th>{t("mine.label.code")}</th>
              <th>{t("mine.label.name")}</th>
              <th style={{ textAlign: "right" }}>
                {t("mine.label.sent_quantity")}
              </th>
              <th style={{ textAlign: "right" }}>{t("mine.label.price")}</th>
              <th style={{ textAlign: "right" }}>Discount/Box</th>
              <th style={{ textAlign: "right" }}>Special discount/Box</th>
              <th style={{ textAlign: "right" }}>Transport Cost/Box</th>
              <th style={{ textAlign: "right" }}>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {record.sale_items.map((item, idx) => {
              return (
                <tr key={item.id + idx}>
                  <td>{item.sale_order_item.product.code}</td>
                  <td>{item.sale_order_item.product.name}</td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={item}>
                      <NumberField
                        source="sent_quantity"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={item}>
                      <NumberField
                        source="price"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={item}>
                      <NumberField
                        source="disc"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={item}>
                      <NumberField
                        source="spc_disc"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={item}>
                      <NumberField
                        // transform={(val) => (+val < 0 ? +val * -1 : +val)}
                        emptyText="0"
                        source="transport_cost"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={item}>
                      <NumberField
                        source="subtotal"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Box>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  );
};

export default SaleNetCardExpandList;
