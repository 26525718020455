import { memo } from "react";
import Grid from "@mui/material/Grid";
import {
  DateInput,
  maxLength,
  minLength,
  minValue,
  number,
  required,
  TextInput,
  useDataProvider,
} from "react-admin";
import { toCurrency } from "../../../../utils/formatter";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormContext } from "react-hook-form";
// import DependOnAutocompleteInput from "../../../common/DependOnAutocompleteInput";
import parserFormatNumInput from "../../../../utils/parserFormatNumInput";
import PurchaseOrderReference from "../../common/PurchaseOrderReference";

const equalOrLessThanDebt = (value, allValues, elm) => {
  const splite = elm.source.split(".");
  const debt = allValues[splite[0]][+splite[1]].debt;
  if (+value > +debt) {
    return (
      "Must be equal or less than " + toCurrency(false).format(debt) + " (debt)"
    );
  }
  return undefined;
};
const equalOrLessThanTotal = (value, allValues, elm) => {
  let remain = allValues.total;
  let count = 0;
  const splite = elm.source.split(".");
  let skipProcessLast = 0;
  for (let i = 0; i < allValues.customer_debt_correction_items.length; i++) {
    const elm = allValues.customer_debt_correction_items[i];
    if (+splite[1] === i) {
      skipProcessLast = elm.amount;
      continue;
    }
    count += +elm.amount;
    if (+count > +allValues.total) {
      return (
        "Must be equal or less than " +
        toCurrency(false).format(remain) +
        " (remaining of total correction)"
      );
    }
    remain -= +elm.amount;
  }
  // kalau yg di validasi index ke 0 sdangkan index sdh ada smpai 5
  // maka skip index 0 dptkan dulu sum total Correction, lalu tambahkan dgn
  // Correction index 0
  count += +skipProcessLast;
  if (+count > +allValues.total) {
    return (
      "Must be equal or less than " +
      toCurrency(false).format(remain) +
      " (remaining of total correction)"
    );
  }
  return undefined;
};

const uniqueItem = (value, allValues, elm) => {
  let count = 0;
  for (let i = 0; i < allValues.customer_debt_correction_items.length; i++) {
    const elm = allValues.customer_debt_correction_items[i];
    if (value === elm.sale_id) {
      if (count > 0) {
        return "server.forbidden_duplication"; // "Duplicate is not allowed";
      }
      count += 1;
    }
  }
  return undefined;
};

const validateCode = [required(), minLength(36), maxLength(36), uniqueItem];
const validateAmount = [
  required(),
  number(),
  minValue(1),
  equalOrLessThanDebt,
  equalOrLessThanTotal,
];

const CstInput = ({
  // NAMA-NAMA SOURCE
  saleIdSource,
  formData,
  dueDateSource,
  debtSource,
  transactionDateSource,
  amountSource,
}) => {
  const { setValue } = useFormContext();

  const dataProvider = useDataProvider();

  const onCodeChangeHandler = async (id) => {
    if (id.length !== 36) return;
    try {
      const { created_at, customer_debt } =
        // see: https://stackoverflow.com/a/332888
        await dataProvider.getListNoParams("sales/" + encodeURIComponent(id));
      setValue(transactionDateSource, created_at);
      setValue(dueDateSource, customer_debt.due_date);
      setValue(debtSource, customer_debt.remain);
    } catch (error) {}
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{
        // "& .MuiGrid-item:not(:first-of-type)": { pt: 0 },
        "& .MuiGrid-item": { py: 0 },
        mt: 0,
      }}
    >
      <Grid item xs={12} lg={6.4}>
        <PurchaseOrderReference
          source={saleIdSource}
          validate={validateCode}
          label="mine.label.sales_number"
          dependOn="customer_id"
          queryName="CustomerDebt.customer_id"
          resource="sales"
          reference="sales"
          defaultFilters={{
            "CustomerDebt.settled_date": "IS NULL",
            "sales.approved": 1,
          }}
          formData={formData}
          onChange={onCodeChangeHandler}
        />
        {/* <DependOnAutocompleteInput
          variant="outlined"
          source={saleIdSource}
          isRequired
          fullWidth
          validate={validateCode}
          optionValue="id"
          optionText="code"
          dependOn="customer_id"
          queryName="customer_id"
          resource="sales/unpaid"
          label="mine.label.sales_number"
          onChange={onCodeChangeHandler}
        /> */}
      </Grid>
      <Grid item xs={12} lg={1.4}>
        <DateInput
          variant="outlined"
          source={transactionDateSource}
          label="mine.label.transaction_date"
          disabled
          fullWidth
        />
      </Grid>
      <Grid item xs={12} lg={1.4}>
        <DateInput
          variant="outlined"
          source={dueDateSource}
          label="mine.label.due_date"
          disabled
          fullWidth
        />
      </Grid>
      <Grid item xs={12} lg={1.4}>
        <TextInput
          variant="outlined"
          source={debtSource}
          label="mine.label.customer_debt"
          fullWidth
          disabled
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
          }}
          {...parserFormatNumInput}
        />
      </Grid>
      <Grid item xs={12} lg={1.4}>
        <TextInput
          variant="outlined"
          source={amountSource}
          label="mine.label.correction"
          fullWidth
          validate={validateAmount}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Rp</InputAdornment>
            ),
          }}
          {...parserFormatNumInput}
        />
      </Grid>
    </Grid>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.quantity === nextProps.quantity &&
    prevProps.code === nextProps.code
  ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};
const Beruk = memo(CstInput, propsAreEqual);

const Inputs = ({
  formData, // The whole form data
  scopedFormData, // The data for this item of the ArrayInput
  getSource, // A function to get the valid source inside an ArrayInput
  ...rest
}) => {
  return (
    <Beruk
      {...scopedFormData}
      formData={formData}
      saleIdSource={getSource("sale_id")}
      transactionDateSource={getSource("sale.created_at")}
      dueDateSource={getSource("sale.customer_debt.due_date")}
      debtSource={getSource("sale.customer_debt.remain")}
      amountSource={getSource("amount")}
    />
  );
};
export default Inputs;
