import {
  DateField,
  useRecordContext,
  RecordContextProvider,
  NumberField,
  TabbedShowLayout,
  useTranslate,
} from "react-admin";
import Box from "@mui/material/Box";

const BadDebtExpandList = () => {
  const record = useRecordContext();
  const t = useTranslate();
  return (
    <TabbedShowLayout
      syncWithLocation={false}
      sx={{
        m: 5,
        backgroundClip: "padding-box",
        boxShadow: (theme) => {
          return theme.components.MuiPaper.styleOverrides.elevation1.boxShadow;
        },
        borderRadius: "4px",
        bgcolor: "background.paper",
      }}
    >
      <TabbedShowLayout.Tab label="sales">
        <Box
          component="table"
          sx={{
            width: "100%",
            // bgcolor: "rgba(0,0,0,.04)",
            fontSize: "smaller",
            borderCollapse: "collapse",
            "& th, td": {
              padding: "6px 8px",
              border: "1px solid #dddddd",
            },
          }}
        >
          <thead>
            <tr>
              <th>No.</th>
              <th>Sale Code</th>
              <th style={{ textAlign: "right" }}>Remaining Debt</th>
              <th>Transaction Date</th>
              <th>Due Date</th>
            </tr>
          </thead>
          <tbody>
            {record.bad_debt_items.map((item, idx) => {
              return (
                <tr key={item.id}>
                  <td>{idx + 1}</td>
                  <td>{item.sale.code}</td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={item}>
                      <NumberField
                        source="sale.customer_debt.remain"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td>
                    <RecordContextProvider value={item}>
                      <DateField
                        source="sale.updated_at"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td>
                    <RecordContextProvider value={item}>
                      <DateField
                        source="sale.customer_debt.due_date"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Box>
      </TabbedShowLayout.Tab>
      {record.transactions.length && (
        <TabbedShowLayout.Tab label={t("mine.tbl_header.journal")}>
          <Box
            component="table"
            sx={{
              width: "100%",
              // bgcolor: "rgba(0,0,0,.04)",
              fontSize: "smaller",
              borderCollapse: "collapse",
              "& th, td": {
                padding: "6px 8px",
                border: "1px solid #dddddd",
              },
            }}
          >
            <thead>
              <tr>
                <th>{t("mine.tbl_header.coa")}</th>
                <th>{t("mine.tbl_header.coa_name")}</th>
                <th>{t("mine.tbl_header.description")}</th>
                <th style={{ textAlign: "right" }}>
                  {t("mine.tbl_header.debit")}
                </th>
                <th style={{ textAlign: "right" }}>
                  {t("mine.tbl_header.credit")}
                </th>
              </tr>
            </thead>
            <tbody>
              {record.transactions.map((item, idx) => {
                return (
                  <tr key={item.id}>
                    <td>{item.coa.code}</td>
                    <td>{item.coa.name}</td>
                    <td>{item.description}</td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="debit"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="credit"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Box>
        </TabbedShowLayout.Tab>
      )}
    </TabbedShowLayout>
  );
};

export default BadDebtExpandList;
