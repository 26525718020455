import { forwardRef } from "react";
import Box from "@mui/material/Box";
import classes from "./SJ.module.css";
import Typography from "@mui/material/Typography";
import { DateField, RecordContextProvider } from "react-admin";

const SJ = forwardRef((props, ref) => {
  // eslint-disable-line max-len
  // const { identity, isLoading: identityLoading } = useGetIdentity();

  let totalMutation = 0;
  return (
    <div
      style={{
        display: "none",
      }}
    >
      <Box ref={ref} sx={{ fontSize: "12px", py: 3, px: 4 }}>
        <Typography sx={{ fontWeight: "bold", mb: 1 }}>
          {props.record.company.name}
        </Typography>
        <table className={classes.headerSj}>
          <thead>
            <tr>
              <th>Kepada Yth</th>
              <th>:</th>
              <th>
                {
                  props.record.sale_items[0].sale_order_item.sale_order.customer
                    .name
                }
              </th>
              <th style={{ width: "20%" }}></th>
              <th>No. Surat Jalan</th>
              <th>:</th>
              <th>{props.record.code}</th>
            </tr>
            <tr>
              <th
                rowSpan={2}
                style={{ paddingTop: "3px", verticalAlign: "baseline" }}
              >
                Alamat
              </th>
              <th
                rowSpan={2}
                style={{ paddingTop: "3px", verticalAlign: "baseline" }}
              >
                :
              </th>
              <th
                rowSpan={2}
                style={{ paddingTop: "3px", verticalAlign: "baseline" }}
              >
                <span style={{ textTransform: "uppercase" }}>
                  {props.record.sale_items[0].sale_order_item.sale_order
                    .customer_address.detail +
                    ", " +
                    props.record.sale_items[0].sale_order_item.sale_order
                      .customer_address.city.name}
                </span>
              </th>
              <th style={{ width: "20%" }}></th>
              <th>Tgl. Pengiriman</th>
              <th>:</th>
              <th>
                <RecordContextProvider value={props.record}>
                  <DateField
                    source="date"
                    sx={{ fontWeight: "inherit", fontSize: "12px" }}
                  ></DateField>
                </RecordContextProvider>
              </th>
            </tr>
            <tr>
              <th style={{ width: "20%" }}></th>
              <th>No. DO</th>
              <th>:</th>
              <th>
                {props.record.sale_items[0].sale_order_item.sale_order.code}
              </th>
            </tr>
            <tr>
              <th>Telp / Fax</th>
              <th>:</th>
              <th>
                {
                  props.record.sale_items[0].sale_order_item.sale_order
                    .customer_address.phone
                }
                {" / "}
                {
                  props.record.sale_items[0].sale_order_item.sale_order
                    .customer_address.fax
                }
              </th>
              <th style={{ width: "20%" }}></th>
              <th>No. Customer</th>
              <th>:</th>
              <th>
                {
                  props.record.sale_items[0].sale_order_item.sale_order.customer
                    .code
                }
              </th>
            </tr>
          </thead>
        </table>
        <Typography
          sx={{ textAlign: "center", fontWeight: "bold", mt: 2, mb: 2 }}
        >
          SURAT JALAN
        </Typography>
        <table className={classes.tableSj}>
          <thead>
            <tr>
              <th>Kode Barang</th>
              <th>Nama Barang - Ukuran</th>
              <th style={{ textAlign: "right" }}>Banyak</th>
              <th style={{ textAlign: "center" }}>Satuan</th>
              <th>Keterangan</th>
            </tr>
          </thead>
          <tbody>
            {props.record.sale_items.map((obj, idx) => {
              totalMutation += obj.sent_quantity;
              return (
                <tr key={obj.sale_order_item.product.id}>
                  <td>{obj.sale_order_item.product.code}</td>
                  <td>{obj.sale_order_item.product.name}</td>
                  <td style={{ textAlign: "right" }}>{obj.sent_quantity}</td>
                  <td style={{ textAlign: "center" }}>BOX</td>
                  {idx === 0 && <td rowSpan={2}>{props.record.note}</td>}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan={2}
                style={{
                  textAlign: "right",
                  verticalAlign: "baseline",
                  fontWeight: "bold",
                  borderRight: "none",
                }}
              >
                Total:
              </td>
              <td
                style={{
                  textAlign: "right",
                  verticalAlign: "baseline",
                  fontWeight: "bold",
                  borderLeft: "none",
                }}
              >
                {totalMutation}
              </td>
              <td colSpan={2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "10px",
                  }}
                >
                  <div>Catatan</div>
                  <div>Lembar ke:</div>
                  <div>
                    <Typography variant="inherit">
                      1. Warna Putih untuk Perusahaan
                    </Typography>
                    <Typography variant="inherit">
                      2. Warna Merah untuk Penerima Barang
                    </Typography>
                    <Typography variant="inherit">
                      3. Warna Kuning untuk Angkutan
                    </Typography>
                    <Typography variant="inherit">
                      4. Warna Biru untuk Gudang
                    </Typography>
                    <Typography variant="inherit">
                      5. Warna Hijau untuk Accounting
                    </Typography>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="inherit" sx={{ mb: 8 }}>
              Mengetahui
            </Typography>
            <Typography>( .............. )</Typography>
            <Typography variant="inherit">K. DEPO</Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="inherit" sx={{ mb: 8 }}>
              Pemeriksa
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItem: "center",
                justifyContent: "space-evenly",
              }}
            >
              <Box sx={{ mr: 1 }}>
                <Typography>( .............. )</Typography>
                <Typography variant="inherit">AKUNTING</Typography>
              </Box>
              <Box>
                <Typography>( .............. )</Typography>
                <Typography variant="inherit">ADMIN</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="inherit" sx={{ mb: 8 }}>
              Pelaksana
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItem: "center",
                justifyContent: "space-evenly",
              }}
            >
              <Box sx={{ mr: 1 }}>
                <Typography>( .............. )</Typography>
                <Typography variant="inherit">K. GUDANG</Typography>
              </Box>
              <Box>
                <Typography>( .............. )</Typography>
                <Typography variant="inherit">ANGKUTAN</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="inherit" sx={{ mb: 8 }}>
              Diterima Oleh
            </Typography>
            <Typography>( .............. )</Typography>
            <Typography variant="inherit">CAP TOKO</Typography>
          </Box>
        </Box>
        <Typography
          component="p"
          variant="caption"
          sx={{ textAlign: "right", mt: 2, fontSize: "10px" }}
        >
          Dibuat oleh:{" "}
          <span style={{ textTransform: "uppercase" }}>
            {props.record.user.first_name + " " + props.record.user.last_name}
          </span>{" "}
          <span>
            <RecordContextProvider value={props.record}>
              <DateField
                showTime
                source="updated_at"
                sx={{ fontWeight: "inherit", fontSize: "12px" }}
              ></DateField>
            </RecordContextProvider>
          </span>
        </Typography>
      </Box>
    </div>
  );
});
export default SJ;
