import { useEffect, useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  DateField,
  NumberField,
  BooleanField,
  BulkDeleteWithConfirmButton,
  useResourceContext,
  useTheme,
  usePermissions,
  FunctionField,
  RecordContextProvider,
} from "react-admin";
import PurchaseAddDialog from "../../components/purchases/create/PurchaseAddDialog";
import PurchaseEditDialog from "../../components/purchases/edit/PurchaseEditDialog";
import purchaseFilterList from "../../components/purchases/filter/purchaseFilterList";
import TableListActions from "../../components/tableListActions/TableListActions";
import PurchaseExpandList from "../../components/purchases/expand/PurchaseExpandList";
import EditBtn from "../../components/editBtn/EditBtn";
import ConfirmBtn from "../../components/confirmBtn/ConfirmBtn";
import DoneIcon from "@mui/icons-material/Done";
import PrintBtn from "../../components/printBtn/PrintBtn";
import PrintHandler from "../../components/printHandler/PrintHandler";
import PJ from "../../components/purchases/print/PJ";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import PurchaseAsideList from "../../components/purchases/aside/PurchaseAsideList";
import DownloadAction from "../../components/tableListActions/DownloadAction";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const PurchaseList = (props) => {
  const resource = useResourceContext();
  const { permissions } = usePermissions();
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const [showAside, setShowAside] = useState(false);
  const [resetPrintContent, setResetPrintContent] = useState();
  const [printRecord, setPrintRecord] = useState({}); // initial value wajib object kosong untuk cek key id di dependency useeffect DeliveryNote.js
  const [theme] = useTheme();

  useEffect(() => {
    setShowAside(resource === "purchase-cards");
  }, [resource]);

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const rowClickHandler = (id, basePath, record) => {
    setShowAside((prev) => !prev);
  };

  const forReport = resource === "purchase-cards";

  const list = useMemo(() => {
    let actions = (
      <TableListActions
        createHandler={handleClickOpenAdd}
        filter={false}
        exporter={false}
      />
    );

    let filterDefaultValues = {
      start_date: firstDate,
      end_date: lastDate,
    };

    let bulkActionButtons = <BulkDeleteWithConfirmButton />;

    let boolAct = [
      <BooleanField
        key={1}
        source="approved"
        label="mine.tbl_header.approved"
        textAlign="center"
      />,
      <WrapperField key={2} label="mine.tbl_header.actions" textAlign="center">
        <WithRecord
          render={(record) => {
            return !record.approved ? (
              <>
                <EditBtn
                  editHandler={handleClickOpenEdit(record.id)}
                  sx={{ mr: 1 }}
                />
                <ConfirmBtn
                  title="mine.message.approve"
                  content="mine.message.approve_content"
                  label="mine.btn.approve"
                  resource={resource + "/approve"}
                  startIcon={<DoneIcon />}
                />
              </>
            ) : (
              <PrintBtn
                dialogProps={{ title: "mine.dialog.print" }}
                recordId={record.id}
                setPrintRecord={setPrintRecord}
                setResetPrintContent={setResetPrintContent}
                tool
              />
            );
          }}
        />
      </WrapperField>,
    ];

    if (forReport) {
      actions = <DownloadAction />;
      filterDefaultValues.approved = 1;
      bulkActionButtons = false;
      boolAct = null;
    }

    return (
      <List
        title={props.title ? props.title : undefined}
        empty={false}
        aside={showAside || forReport ? <PurchaseAsideList /> : null}
        actions={actions}
        filters={purchaseFilterList(permissions)}
        filterDefaultValues={filterDefaultValues}
        queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
        sx={{
          flexDirection: "column",
        }}
      >
        <DatagridConfigurable
          bulkActionButtons={bulkActionButtons}
          rowClick={rowClickHandler}
          expand={<PurchaseExpandList forReport={forReport} />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField label="mine.label.company" source="company.alias" />
          ) : null}
          <TextField source="code" label="mine.label.code" />
          <DateField source="date" label="mine.label.date" />
          <TextField label="mine.label.supplier" source="supplier.name" />
          <TextField label="mine.label.delivery_note" source="delivery_note" />
          <FunctionField
            textAlign="right"
            label="Box"
            render={(record) => {
              let rec = { total_received: 0 };
              for (let i = 0; i < record.purchase_items.length; i++) {
                rec.total_received +=
                  +record.purchase_items[i].received_quantity;
              }
              return (
                <RecordContextProvider value={rec}>
                  <NumberField source="total_received" />
                </RecordContextProvider>
              );
            }}
          />
          <NumberField source="total" label="mine.label.value" />
          <TextField source="note" label="mine.label.note" />
          {boolAct}
        </DatagridConfigurable>
      </List>
    );
  }, [showAside, forReport, theme]);
  return (
    <>
      {list}
      {!forReport && (
        <>
          <PurchaseAddDialog open={openAdd} setOpen={setOpenAdd} />
          <PurchaseEditDialog
            open={openEdit}
            setOpen={setOpenEdit}
            recordId={recordId}
          />

          <PrintHandler
            record={printRecord}
            resetPrintContent={resetPrintContent}
            Content={PJ}
          />
        </>
      )}
    </>
  );
};

export default PurchaseList;
